<template>
  <section class="message milestone">
    <section>
      <hub-icon :name="icon" :class="action" size="xlg" />
      <div>
        <h4>{{ `${metadata.title} ${action}` }}</h4>

        <div class="assignees">
          <em><label>Assignees</label>: {{ metadata.assignees?.filter(ra => ra) || [] }}</em>
        </div>
      </div>
      <div class="metadata">
        <div>
          {{ new Date(createdAt).toLocaleString() }}
        </div>
        <div v-if="metadata.dueAt">
          Due At <label>{{ new Date(metadata.dueAt).toLocaleDateString() }}</label>
        </div>
        <div v-else>
          <em>No due date</em>
        </div>
        <div>
          <em
            >By <label>{{ createdBy }}</label></em
          >
        </div>
      </div>
    </section>
  </section>
</template>

<script>
import Icon from './../../common/Icon';

export default {
  components: {
    'hub-icon': Icon
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    message: {
      type: String,
      required: true
    },
    type: {
      type: String,
      required: true
    },
    createdBy: {
      type: String,
      required: true
    },
    createdAt: {
      type: Date,
      required: true
    },
    metadata: {
      type: Object,
      default: () => null
    }
  },
  computed: {
    action() {
      return this.type.split(':')[1];
    },
    icon() {
      switch (this.action) {
        case 'created':
          return 'sign-direction-plus';
        case 'deleted':
          return 'sign-direction-remove';
        default:
          return 'sign-direction';
      }
    },
    class() {
      switch (this.action) {
        case 'created':
          return 'green';
        case 'deleted':
          return 'red';
        default:
          return '';
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.created {
  color: var(--theme-success);
}
.finished {
  color: var(--theme-success);
}
.deleted {
  color: var(--theme-error);
}
.message.milestone {
  display: grid;
  grid-template-rows: minmax(0, 1fr);
  grid-template-columns: minmax(0, 1fr);
  height: 100%;
  width: 100%;
  background-color: var(--theme-surface);
  border-radius: 3px;
  padding: 0.75rem 1rem 0.75rem 1.25rem;
  grid-gap: 0.25rem;

  label {
    font-weight: 600;
  }

  em {
    font-size: 0.65rem;
    font-style: italic;
    color: var(--theme-on-background-accent);
  }

  > section {
    display: grid;
    grid-template-columns: 50px minmax(0, 1fr) max-content max-content;
    grid-template-rows: max-content;
    grid-gap: 0.5rem;
    align-items: center;

    h4 {
      font-weight: 700;
    }
  }

  .metadata {
    text-align: right;
    font-size: 0.65rem;
  }

  i {
    cursor: pointer;
  }
}
</style>
